import React from "react"
import PageLayout from "../components/new-pagelayout"
import { graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  const {
    site: {
      siteMetadata: { title: siteTitle },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <PageLayout pageTitle="Start">
      {/* <h1>{siteTitle}</h1> */}
      <section class="pb-20 max-w-3xl mx-auto">
        <div class="container px-4 mx-auto pt-12">
          <div class="flex flex-wrap items-center mx-4">
            <div>
              <div class="font-semibold text-xs text-blue-400">
                Introduktion till {siteTitle}
              </div>
              <h2 class="mt-8 mb-6 lg:mb-12 text-3xl lg:text-4xl font-semibold">
                Skyddar privatpersoners integritet och företags tillgångar
              </h2>
            </div>
            <div class="w-full md:w-1/2 px-4 mb-6 md:mb-0">
              <div class="max-w-lg mb-6 lg:mb-12">
                <p class="text-xl mb-3 lg:mb-6 text-gray-500">
                  Vi älskar drönare och alla nya möjligheter denna nya teknik
                  för med sig. Tyvärr används tekniken även för mindre positiva
                  ändamål. Drone Bone tillhandahåller system för att skydda
                  privatpersoners integritet och företags tillgångar från denna
                  nya typ av hot – drönare.
                </p>
                <p class="text-xl text-gray-500">
                  Genom att installera Drone Bones system skapas en
                  tröskeleffekt för aktörer som vill fotografera eller direkt
                  påverka ett område med hjälp av drönare.
                </p>
              </div>
              <div class="flex flex-wrap">
                <a
                  class="inline-block px-6 py-4 mb-3 mr-4 text-sm font-medium leading-normal bg-red-400 hover:bg-red-300 text-white rounded transition duration-200"
                  href="/kontakt"
                >
                  Kontakta oss
                </a>
                <a
                  class="inline-block px-6 py-4 mb-3 text-sm font-medium leading-normal hover:text-gray-700 rounded border"
                  href="/produkter"
                >
                  Läs mer om produkterna
                </a>
              </div>
            </div>
            <div class="relative w-full md:w-1/2 px-4">
              <StaticImage
                class="rounded-xl object-cover"
                alt="Drönare som flyger i solnedgången"
                src="../images/start.jpeg"
              />
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  )
}

export default IndexPage
